import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '#utils/utils';

export const rewardContainer = cva(
  ['group/reward-stack', 'transition-all ease-in-out duration-500 translate-x-0'],
  {
    variants: {
      position: {
        0: [
          'z-30',
          'group-data-[expendable=true]/rewards:group-data-[expanded=false]/rewards:group-hover/rewards:translate-x-[134px]',
          'group-data-[expanded=false]/rewards:translate-x-[142px]',
        ],
        1: [
          'z-20',
          'group-data-[expendable=true]/rewards:group-data-[expanded=false]/rewards:group-hover/rewards:translate-x-[67px]',
          'group-data-[expanded=false]/rewards:translate-x-[72px]',
        ],
        2: ['z-10'],
      },

      scale: {
        0: ['group-data-[expanded=false]/rewards:scale-100'],
        1: ['group-data-[expanded=false]/rewards:scale-90'],
        2: ['group-data-[expanded=false]/rewards:scale-75'],
      },
    },
  },
);

export const stage = cn([
  'pointer-events-none',
  'rounded-quest-card-questcard flex items-center justify-end transition-all duration-400',
  'group-data-[expanded=false]/rewards:w-reward-card-img-sm group-data-[expanded=false]/rewards:h-reward-card-img-sm',
  'group-data-[expanded=true]/rewards:pr-quest-card-md-left group-data-[expanded=true]/rewards:backdrop-blur-md group-data-[expanded=true]/rewards:absolute group-data-[expanded=true]/rewards:inset-0',
]);

export type RewardContainerVariants = VariantProps<typeof rewardContainer>;
