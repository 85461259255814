'use client';

import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';

import { useRecommendedQuests } from '@zealy/queries';

import type { QuestData } from '#components/QuestCard';
import { Carousel } from '#components';
import { QuestCard } from '#components/QuestCard';
import { useEventTracker } from '#context';

export default function RecommendedQuestsCarousel() {
  const t = useTranslations('explore');
  const { data: quests = [] } = useRecommendedQuests();
  const { analytics } = useEventTracker();

  if (quests.length === 0) {
    return null;
  }

  return (
    <Carousel title={t('recommended-quests-title')} className="py-400">
      {quests.map((quest, index) => (
        <QuestCard
          key={quest.id}
          quest={
            {
              ...quest,
              canRetry: true,
              conditions: [],
            } as unknown as QuestData
          }
          displayCommunityData={{
            name: quest.communityName,
            imageUrl: quest.communityImageUrl,
          }}
          onClick={() => {
            analytics.track('Visit Recommended Quest', {
              questId: quest.id,
              position: index + 1,
            });
          }}
          href={`/cw/${quest.subdomain}/questboard/${quest.categoryId}/${quest.id}`}
          className={cx(
            'w-[350px] max-w-[85vw] h-full transition-transform hover:scale-[1.01] duration-200 ease-out',
            'border border-component-tertiary hover:border-component-tertiary-hover',
          )}
        />
      ))}
    </Carousel>
  );
}
