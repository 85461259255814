import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { Badge } from '@zealy/design-system';
import { ChevronRightFilled } from '@zealy/icons';

import type { QuestData } from '#components/QuestCard/QuestCard.types';
import { RewardCard } from '#components/RewardCard';
import { flattenRewardMethod } from '#utils/flattenReward';

import type { RewardContainerVariants } from './RewardStack.style';
import { rewardContainer, stage } from './RewardStack.style';

const rewardOrder = {
  token: 0,
  nft: 1,
  role: 2,
  other: 3,
  xp: 4,
} as const;

export const RewardStack = ({ quest }: { quest: QuestData }) => {
  const [expanded, setExpanded] = useState(false);

  const t = useTranslations('common');

  const rewardCards = (quest.rewards || [])
    .sort((a, b) => rewardOrder[a.type] - rewardOrder[b.type])
    .slice(0, 3);
  const rewardsCount = rewardCards.length;

  if (!rewardsCount) return null;

  const toggleExpanded = () => setExpanded(prev => !prev);

  const claimAvailable = quest.rewards.some(r => r.status === 'claim-available');

  const isRewardsAreaExpandable = !claimAvailable && rewardsCount > 1;
  const isRewardsAreaDisabled = quest.locked;

  const onRewardsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isRewardsAreaDisabled) return;

    if (isRewardsAreaExpandable) toggleExpanded();
    else return;

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <button
      data-testid="rewards"
      onClick={onRewardsClick}
      className={clsx(
        'group/rewards z-10 flex items-center content-center py-quest-card-md-left pr-quest-card-md-left right-0 min-w-[80px]',
        expanded && 'absolute inset-0',
      )}
      data-expanded={expanded}
      data-expendable={isRewardsAreaExpandable}
    >
      <div className={stage}>
        <ChevronRightFilled className="opacity-0 transition-transform duration-1000 translate-x-400 group-data-[expanded=true]/rewards:opacity-100 group-data-[expanded=true]/rewards:translate-x-0 absolute left-400" />
        <div className="flex gap-200 items-center group-data-[expanded=false]/rewards:-mr-100 group-data-[expanded=false]/rewards:[&>*:not(:first-child)>div>div>svg]:hidden">
          {rewardCards.map(({ method, ...reward }, index) => {
            const diff = 3 - rewardsCount;
            const position = (index + diff) as RewardContainerVariants['position'];
            const scale = index as RewardContainerVariants['scale'];

            const rewardClassName = rewardContainer({
              position,
              scale,
            });
            return (
              <RewardCard
                data-testid="reward-card"
                key={`${reward.type}-${index}`}
                {...{
                  ...reward,
                  ...flattenRewardMethod(method),
                  disabled: isRewardsAreaDisabled,
                  bgColor: 'var(--color-bg-secondary)',
                  overlay: index > 0 && !expanded,
                  className: rewardClassName,
                }}
              />
            );
          })}
        </div>
      </div>
      {claimAvailable && (
        <Badge size="sm" className="absolute right-[38px] bottom-100 z-[200] bg-brand-primary">
          {t('claim')}
        </Badge>
      )}
    </button>
  );
};
